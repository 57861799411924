<template>
  <div>
    <!--Progress Bar-->
    <v-dialog v-model="progress" max-width="290" persistent hide-overlay>
      <v-card color="primary" dark>
        <v-card-text class="text-center">
          Loading
          <v-progress-linear indeterminate color="white" class="mb-0" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="detail.open" scrollable persistent max-width="1000">
      <v-card>
        <v-card-title class="secondary white--text headline font-weight-medium">
          Design Details
          <v-spacer />
          <v-btn text icon color="white" @click="detail.open = false">
            <v-icon>fal fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container fluid grid-list-sm>
            <v-layout row wrap>
              <v-flex xs12 md4>
                <v-layout row wrap>
                  <v-flex xs12>
                    <v-textarea
                      :value="designsStore.design.d_jobName"
                      label="Job Name"
                      placeholder=" "
                      persistent-placeholder
                      flat
                      readonly
                      loading="false"
                      class="pb-0"
                      no-resize
                      rows="3"
                    />
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      :value="designsStore.design.d_productColor"
                      label="Product Color"
                      placeholder=" "
                      persistent-placeholder
                      flat
                      readonly
                      loading="false"
                      class="pb-0"
                    />
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      :value="designsStore.design.d_designName"
                      label="Design Name"
                      placeholder=" "
                      persistent-placeholder
                      flat
                      readonly
                      loading="false"
                      class="pb-0"
                    />
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs12 md3>
                    <v-text-field
                      :value="designsStore.design.d_sizeWidth"
                      label="Width"
                      placeholder=" "
                      persistent-placeholder
                      flat
                      readonly
                      loading="false"
                      class="pb-0"
                    />
                  </v-flex>
                  <v-flex xs12 md3>
                    <v-text-field
                      :value="designsStore.design.d_sizeHeight"
                      label="Height"
                      placeholder=" "
                      persistent-placeholder
                      flat
                      readonly
                      loading="false"
                      class="pb-0"
                    />
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs12 md3>
                    <v-text-field
                      :value="designsStore.design.d_pricing"
                      label="Pricing Count"
                      placeholder=" "
                      persistent-placeholder
                      flat
                      readonly
                      loading="false"
                      class="pb-0"
                    />
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs12>
                    <v-textarea
                      :value="designLocation(designsStore.design)"
                      label="Location(s)"
                      placeholder=" "
                      persistent-placeholder
                      flat
                      readonly
                      loading="false"
                      class="pb-0"
                      no-resize
                      rows="3"
                    />
                  </v-flex>
                </v-layout>
              </v-flex>

              <v-flex xs12 md4>
                <div class="caption">
                  Colors
                </div>
                <v-list dense>
                  <template v-for="(item, i) in designsStore.design.j_colors">
                    <v-list-item :key="i" class="px-0">
                      <v-list-item-content>
                        <v-list-item-title class="subtitle-1 text-uppercase">
                          {{ item.d_sort }} - {{ item.d_color }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-list>
              </v-flex>

              <v-flex xs12 md4>
                <div class="caption">
                  Image Preview
                </div>
                <v-img
                  contain
                  :src="imageURL"
                  max-width="400"
                  max-height="400"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-container v-if="!progress" fluid fill-height grid-list-lg>
      <v-layout row wrap>
        <v-flex xs12>
          <v-toolbar flat dense dark color="secondary">
            <v-icon>fal fa-image</v-icon>
            <v-toolbar-title class="pl-2">
              Designs
            </v-toolbar-title>
          </v-toolbar>

          <!-- Filter Toolbar -->
          <v-toolbar flat color="white" class="py-0">
            <!--  Filter Button -->
            <v-menu
              v-model="designFilter"
              offset-y
              right
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on }">
                <v-btn rounded color="primary" v-on="on">
                  <v-icon small left> fal fa-filter </v-icon
                  ><span
                    v-if="
                      designsStore.filters.customerID ||
                        designsStore.filters.designName ||
                        designsStore.filters.jobName ||
                        designsStore.filters.productColor ||
                        designsStore.filters.location
                    "
                    >Filtered</span
                  ><span v-else>Filter</span>
                </v-btn>
              </template>

              <v-card max-width="400px">
                <v-card-text>
                  <v-container fluid grid-list-sm>
                    <v-layout row wrap>
                      <v-flex
                        v-if="
                          designsStore.filters.customerID ||
                            designsStore.filters.designName ||
                            designsStore.filters.jobName ||
                            designsStore.filters.productColor ||
                            designsStore.filters.location
                        "
                        xs12
                      >
                        <v-btn
                          block
                          rounded
                          color="primary"
                          @click="clearFilter()"
                        >
                          <v-icon small left> fal fa-times </v-icon>Clear
                          Filters
                        </v-btn>
                      </v-flex>

                      <v-flex
                        xs12
                        v-if="usersStore.authUser.customers.length > 0"
                      >
                        <v-autocomplete
                          v-model="filterBar.customerID"
                          :items="usersStore.authUser.customers_view"
                          item-text="d_companyName"
                          item-value="id"
                          label="Customer"
                          placeholder=" "
                          persistent-placeholder
                          clearable
                          clear-icon="fal fa-times-circle"
                        />
                      </v-flex>

                      <v-flex xs12>
                        <v-text-field
                          v-model="filterBar.jobName"
                          label="Job Name"
                          placeholder=" "
                          persistent-placeholder
                        />
                      </v-flex>
                      <v-flex xs12>
                        <v-text-field
                          v-model="filterBar.designName"
                          label="Design Code"
                          placeholder=" "
                          persistent-placeholder
                        />
                      </v-flex>
                      <v-flex xs12>
                        <v-text-field
                          v-model="filterBar.productColor"
                          label="Product Color"
                          placeholder=" "
                          persistent-placeholder
                        />
                      </v-flex>
                      <v-flex xs12>
                        <v-autocomplete
                          v-model="filterBar.location"
                          :items="designLocationsStore.designLocations"
                          item-text="d_name"
                          item-value="fk_internalID"
                          label="Location"
                          placeholder=" "
                          persistent-placeholder
                        />
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn rounded block color="primary" @click="filter()">
                    <v-icon small left> fal fa-check </v-icon>Submit
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-toolbar>

          <!-- Pagination Toolbar -->
          <pagination
            :show="
              designsStore.designs.data &&
              designsStore.designs.data.length > 0 &&
              designsStore.designs.last_page > 1
                ? true
                : false
            "
            :current-page="designsStore.designs.current_page"
            :last-page="designsStore.designs.last_page"
            @paginate-event="filter($event)"
          />

          <!-- Designs List -->
          <v-list
            v-if="designsStore.designs.data.length > 0"
            three-line
            class="py-0"
          >
            <template v-for="(item, i) in designsStore.designs.data">
              <v-list-item :key="i" @click="openDetail(item.id)">
                <v-list-item-content>
                  <v-list-item-title
                    v-if="usersStore.authUser.customers.length > 0"
                    class="font-weight-bold"
                  >
                    {{ item.customer.d_companyName }}
                  </v-list-item-title>

                  <v-list-item-title class="text--primary">
                    {{ item.d_jobName }}
                  </v-list-item-title>
                  <v-list-item-title>
                    Code: {{ item.d_designName }}
                  </v-list-item-title>
                  <v-list-item-title class="text-uppercase">
                    Product Color: {{ item.d_productColor }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-list-item-action-text class="subtitle-1 secondary--text">
                    {{ item.d_sizeWidth }}W x {{ item.d_sizeHeight }}H
                  </v-list-item-action-text>
                  <v-list-item-action-text class="subtitle-1 secondary--text">
                    Pricing Count: {{ item.d_pricing }}
                  </v-list-item-action-text>
                </v-list-item-action>
              </v-list-item>
              <v-divider />
            </template>
          </v-list>

          <v-list v-else>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="red--text subtitle-1 text-xs-center">
                  No Designs Exist
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mixin } from "@/mixins/mixin.js";
import { clientID, clientSecret, getHeader } from "@/env";
import Pagination from "@/components/Pagination";

export default {
  name: "Designs",
  components: {
    Pagination
  },
  mixins: [mixin],
  computed: {
    ...mapState({
      usersStore: state => state.usersStore,
      designsStore: state => state.designsStore,
      designLocationsStore: state => state.designLocationsStore
    })
  },
  data() {
    return {
      progress: false,
      designFilter: "",
      filterBar: {
        customerID: "",
        designName: "",
        jobName: "",
        productColor: "",
        location: ""
      },
      detail: {
        open: false
      },
      imageURL: ""
    };
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      this.progress = true;
      const user = this.$store.getters["usersStore/getAuthUser"];

      const filters = this.$store.getters["designsStore/getFilters"];

      this.filterBar.customerID = filters.customerID;
      this.filterBar.designName = filters.designName;
      this.filterBar.jobName = filters.jobName;
      this.filterBar.productColor = filters.productColor;
      this.filterBar.location = filters.location;

      const data = {
        fk_customerID: user.fk_customerID,
        fk_userID: user.id,
        customerID: this.filterBar.customerID,
        designName: this.filterBar.designName,
        jobName: this.filterBar.jobName,
        productColor: this.filterBar.productColor,
        location: this.filterBar.location,
        page: filters.page
      };

      if (!user) {
        this.$router.push({ name: "logout" });
      } else {
        await this.$store.dispatch("designsStore/fetchByCustomer", data);

        const locations = this.$store.getters[
          "designLocationsStore/getLocations"
        ];
        if (locations.length === 0) {
          await this.$store.dispatch("designLocationsStore/fetch");
        }

        this.progress = false;
      }
    },

    async filter(page) {
      this.designFilter = false;
      const user = this.$store.getters["usersStore/getAuthUser"];

      const data = {
        fk_customerID: user.fk_customerID,
        fk_userID: user.id,
        customerID: this.filterBar.customerID,
        designName: this.filterBar.designName,
        jobName: this.filterBar.jobName,
        productColor: this.filterBar.productColor,
        location: this.filterBar.location,
        page
      };

      this.$store.dispatch("designsStore/storeFilters", data);
      await this.$store.dispatch("designsStore/fetchByCustomer", data);
    },

    async clearFilter() {
      this.designFilter = false;
      const user = this.$store.getters["usersStore/getAuthUser"];

      this.filterBar.customerID = "";
      this.filterBar.designName = "";
      this.filterBar.jobName = "";
      this.filterBar.productColor = "";
      this.filterBar.location = "";

      const data = {
        fk_customerID: user.fk_customerID,
        fk_userID: user.id,
        customerID: this.filterBar.customerID,
        designName: this.filterBar.designName,
        jobName: this.filterBar.jobName,
        productColor: this.filterBar.productColor,
        location: this.filterBar.location,
        page: 1
      };
      this.$store.dispatch("designsStore/storeFilters", data);
      await this.$store.dispatch("designsStore/fetchByCustomer", data);
    },

    async openDetail(id) {
      await this.$store.dispatch("designsStore/read", id);
      const design = this.$store.getters["designsStore/getDesign"];
      if (design.d_path) {
        this.imageURL = await this.designURL(design.d_path);
      }
      this.detail.open = true;
    },

    checkComma(value) {
      if (value) {
        return ", ";
      }
      return "";
    },

    designLocation(design) {
      let location = "";
      if (design.f_placementFrontLeftChest === 1) {
        location += `${this.checkComma(location)}Chest Left`;
      }
      if (design.f_placementFrontRightChest === 1) {
        location += `${this.checkComma(location)}Chest Right`;
      }
      if (design.f_placementFrontLeftSleeve === 1) {
        location += `${this.checkComma(location)}Sleeve Left`;
      }
      if (design.f_placementFrontRightSleeve === 1) {
        location += `${this.checkComma(location)}Sleeve Right`;
      }
      if (design.f_placementFrontTopCenter === 1) {
        location += `${this.checkComma(location)}Front Center Top`;
      }
      if (design.f_placementFrontCenterChest === 1) {
        location += `${this.checkComma(location)}Front Full`;
      }
      if (design.f_placementBackTopCenter === 1) {
        location += `${this.checkComma(location)}Back Center Top`;
      }
      if (design.f_placementBackCenter === 1) {
        location += `${this.checkComma(location)}Back Full`;
      }
      if (design.f_placementHatFrontCenter === 1) {
        location += `${this.checkComma(location)}Hat Front`;
      }
      if (design.f_placementHatBackCenter === 1) {
        location += `${this.checkComma(location)}Hat Back`;
      }
      if (design.f_placementHatLeftSide === 1) {
        location += `${this.checkComma(location)}Hat Left Side`;
      }
      if (design.f_placementHatRightSide === 1) {
        location += `${this.checkComma(location)}Hat Right Side`;
      }
      if (design.f_placementOther === 1) {
        location += `${this.checkComma(location)}Other`;
      }
      return location;
    },

    async designURL(path) {
      const data = {
        file: path
      };
      const response = await axios.post(
        "/api/artwork/presignedurldesign",
        data,
        { headers: getHeader() }
      );
      return response.data;
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
