<template>
  <div>
    <v-toolbar
      v-if="show === true"
      flat
      dense
      color="white"
    >
      <!--First-->
      <v-btn
        :disabled="currentPage <= 1"
        icon
        @click="paginate(1)"
      >
        <v-icon color="primary">
          fal fa-step-backward
        </v-icon>
      </v-btn>

      <!--Previous-->
      <v-btn
        :disabled="currentPage <= 1"
        icon
        @click="paginate(currentPage - 1)"
      >
        <v-icon color="primary">
          fal fa-caret-left
        </v-icon>
      </v-btn>

      <!--Display Of Current Page And Total Pages-->
      <span>{{ currentPage }}
        of {{ lastPage }}</span>

      <!--Next-->
      <v-btn
        :disabled="currentPage ===
          lastPage"
        icon
        @click="paginate(currentPage + 1)"
      >
        <v-icon color="primary">
          fal fa-caret-right
        </v-icon>
      </v-btn>

      <!--Last-->
      <v-btn
        :disabled="currentPage ===
          lastPage"
        icon
        @click="paginate(lastPage)"
      >
        <v-icon color="primary">
          fal fa-step-forward
        </v-icon>
      </v-btn>
    </v-toolbar>
  </div>      
</template>
    
<script>
export default {
  name: 'Pagination',
  props: {
    currentPage: Number,
    lastPage: Number,
    show: Boolean
  },
  methods: {
    paginate(pageNumber) {
      this.$emit('paginate-event',pageNumber)
    }
  }

}
</script>

<style>

</style>